import { useAuth0 } from '@auth0/auth0-react';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';

interface AuthMutationOptions<TData, TVariables, TError>
  extends Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'> {
  url: string;
  method?: 'POST' | 'PUT' | 'PATCH';
}

export function useAuthMutation<
  TData = unknown,
  TVariables = unknown,
  TError = unknown,
>(
  options: AuthMutationOptions<TData, TVariables, TError>,
): UseMutationResult<TData, TError, TVariables> {
  const { getAccessTokenSilently } = useAuth0();

  const mutationFn = async (variables: TVariables): Promise<TData> => {
    const token = await getAccessTokenSilently();
    const { url, method = 'POST' } = options;

    const response = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(variables),
    });

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(errorData?.message || 'An error occurred');
      (error as any).status = response.status; // Add status to the error object
      throw error;
    }

    return response.json();
  };

  return useMutation<TData, TError, TVariables>({
    ...options,
    mutationFn,
  });
}
