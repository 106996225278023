import {
  Flex,
  Badge,
  Button,
  Icon,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import { mdiOpenInNew } from '@mdi/js';
import { getInstallationPath } from 'features/marketplace/config/routes';
import { InstalledApplication } from 'features/marketplace/shared/types';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  useGetInstalledApplications,
  useInstallApplication,
} from '../../hooks';

export const AppActionSection = () => {
  const history = useHistory();
  const { appId } = useParams<{ appId: string }>();

  const toast = useToast();
  const getOnError = (message: string) => () =>
    toast({
      status: 'error',
      description: message,
    });

  const { mutate, isLoading: isInstallationLoading } = useInstallApplication({
    onError: getOnError('Something went wrong'),
    onSuccess: ({ id: installationId, appId }: InstalledApplication) =>
      history.push({
        pathname: getInstallationPath({ appId, installationId }),
        search: history.location.search,
      }),
  });
  const installApp = useCallback(() => mutate({ appId }), [appId, mutate]);

  const { data, isFetching: isGetInstalledApplicationsFetching } =
    useGetInstalledApplications({
      appId,
      pageSize: '1',
    });

  return (
    <Skeleton
      height='10'
      isLoaded={!isGetInstalledApplicationsFetching}
      display='flex'
      justifyContent='center'
      alignItems='center'
      alignSelf='center'
    >
      {data?.totalCount ? (
        <Flex justifyContent='center' alignItems='center' gap={6}>
          <Badge colorScheme='success'>installed</Badge>
          <Button
            //TODO add onClick functionality when url discussion is resolved
            //   onClick={() =>
            //     window.open(
            //       provision?.url ?? '',
            //       '_blank',
            //       'noopener,noreferrer',
            //     )
            //   }
            variant='ghost'
            rightIcon={
              <Icon>
                <path d={mdiOpenInNew} />
              </Icon>
            }
          >
            Open app
          </Button>
        </Flex>
      ) : (
        <Button
          variant='solid'
          flexShrink={0}
          data-testid='install-application-button'
          data-behavior-analytics-id='install application'
          onClick={installApp}
          isLoading={isInstallationLoading}
          loadingText='Installing'
        >
          Install
        </Button>
      )}
    </Skeleton>
  );
};
