import React from 'react';
import { useTopPicks } from '../hooks/useTopPicks';
import { TopPicksGrid } from 'features/marketplace/features/browse/layouts/TopPicksGrid';
import {
  StatusView,
  SkeletonCard,
  AppCard,
} from 'features/marketplace/shared/components';
import { t } from 'features/marketplace/shared/utils/i18n';
import { alertCicle, cactusNeutral } from '../../../shared/utils/icons';
import { useGetInstalledApplications } from '../../app-details/hooks';

export const TopPicks: React.FC = () => {
  const { data, isLoading, error, refetch } = useTopPicks();

  const {
    data: installedApplications,
    isLoading: isGetInstalledApplicationsLoading,
    isFetching,
  } = useGetInstalledApplications({});

  if (isLoading || isGetInstalledApplicationsLoading) {
    return (
      <TopPicksGrid>
        {[...Array(6)].map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </TopPicksGrid>
    );
  }

  if (error) {
    return (
      <StatusView
        icon={alertCicle}
        iconAlt='alert icon'
        title='Something went wrong'
        action={{
          label: t('Try again'),
          onClick: refetch,
        }}
      />
    );
  }

  if (!data?.length) {
    return (
      <StatusView
        icon={cactusNeutral}
        iconAlt='desert icon'
        title='No applications yet'
      />
    );
  }

  return (
    <TopPicksGrid>
      {data.map((app) => {
        const isInstalled =
          installedApplications?.data?.some(({ appId }) => appId === app.id) ??
          false;
        return (
          <AppCard
            key={app.id}
            app={app}
            isInstalled={isInstalled}
            isFetching={isFetching}
          />
        );
      })}
    </TopPicksGrid>
  );
};
