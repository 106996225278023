import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router';
import { StatusView } from '../../shared/components';
import { defaultAppValues } from '../../shared/utils/constants';
import { Header, Body, Sidebar } from './components';
import { useGetApplication } from './hooks';
import { AppDetailsSkeleton } from './AppDetailsSkeleton';
import { alertCicle } from '../../shared/utils/icons';

export const AppDetails = () => {
  const history = useHistory();
  const { appId } = useParams<{ appId: string }>();

  const { data, isLoading, error } = useGetApplication(appId);

  if (error) {
    return (
      <StatusView
        icon={alertCicle}
        iconAlt='alert icon'
        title='Failed to fetch this application'
      />
    );
  }

  const { name, presentation } = data ?? defaultAppValues;
  const {
    categories,
    description,
    screenshots,
    websiteUrl,
    supportUrl,
    privacyPolicyUrl,
    termsOfServiceUrl,
    iconUrl,
  } = presentation;

  if (isLoading) return <AppDetailsSkeleton />;
  return (
    <Container
      maxW='container.2xl'
      px={{ base: '4', md: '8', lg: '16' }}
      overflowY='auto'
      // css={{
      //   '&::-webkit-scrollbar': {
      //     display: 'none',
      //   },
      //   scrollbarWidth: 'none', // Firefox
      // }}
    >
      <Flex flexDir='column' py={6} gap={6}>
        <Breadcrumb data-testid='app-details-breadcrumb'>
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() =>
                history.push({
                  pathname: '/marketplace',
                  search: history.location.search,
                })
              }
            >
              Marketplace
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Header name={name} iconUrl={iconUrl} />
        <Divider />
        <Flex
          data-testid='app-details-body'
          justifyContent='space-between'
          gap={8}
          flexWrap='wrap'
        >
          <Body description={description} screenshots={screenshots} />
          <Sidebar
            websiteUrl={websiteUrl}
            supportUrl={supportUrl}
            privacyPolicyUrl={privacyPolicyUrl}
            termsOfServiceUrl={termsOfServiceUrl}
            categories={categories}
          />
        </Flex>
      </Flex>
    </Container>
  );
};
