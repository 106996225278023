import { getBaseUrl } from '../../../config/env';

export const marketplaceApi = {
  get_application: {
    url: (appId: string) => `${getBaseUrl()}/api/v1/Applications/${appId}`,
    method: 'GET' as const,
  },
  get_installed_applications: {
    url: ({
      page,
      pageSize,
      appId,
    }: {
      page: string;
      pageSize: string;
      appId: string;
    }) => {
      const params = {
        pagenumber: page,
        pagesize: pageSize,
        ...(appId && { appid: appId }),
      };

      const queryString = new URLSearchParams(params).toString();
      return `${getBaseUrl()}/api/v1/Installations?${queryString}`;
    },
    method: 'GET' as const,
  },
  get_installed_application: {
    url: (installationId: string) =>
      `${getBaseUrl()}/api/v1/Installations/${installationId}`,
    method: 'GET' as const,
  },
  install_application: {
    url: () => `${getBaseUrl()}/api/v1/Installations`,
    method: 'POST' as const,
  },
};
