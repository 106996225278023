export const MARKETPLACE_ROUTES = {
  ROOT: '/marketplace',
  BROWSE: '/', // /marketplace/
  APP_DETAILS: '/app/:appId', // /marketplace/app/:appId
  INSTALLATION: '/app/:appId/install', // /marketplace/app/:appId/install
  INSTALLATION_SUCCESS: '/app/:appId/install/:installationId', // /marketplace/app/:appId/install/:installationId
} as const;

// Application
export const getAppPath = (appId: string) => {
  return `${MARKETPLACE_ROUTES.ROOT}${MARKETPLACE_ROUTES.APP_DETAILS.replace(
    ':appId',
    appId,
  )}`;
};

// Installation
export const getInstallationPath = ({
  appId,
  installationId,
}: {
  appId: string;
  installationId: string;
}) => {
  return `${
    MARKETPLACE_ROUTES.ROOT
  }${MARKETPLACE_ROUTES.INSTALLATION_SUCCESS.replace(':appId', appId).replace(
    ':installationId',
    installationId,
  )}`;
};
