const MARKETPLACE_APPS = 'marketplace/apps';
const MARKETPLACE_LIST = `${MARKETPLACE_APPS}/list`;
export const MARKETPLACE_INSTALLED_LIST = `${MARKETPLACE_APPS}/installed/list`;

export const MARKETPLACE_KEYS = {
  apps: {
    all: [MARKETPLACE_APPS],
    list: [MARKETPLACE_LIST],
    installed_applications: ({
      page,
      pageSize,
    }: {
      page: string;
      pageSize: string;
    }) => [MARKETPLACE_INSTALLED_LIST, { page, pageSize }] as const,
    installed_application: (appId: string) =>
      [`${MARKETPLACE_APPS}/installed/${appId}`] as const,
    details: (appId: string) => [`${MARKETPLACE_APPS}/details/${appId}`],
  },
} as const;
