import { Route, Switch } from 'react-router-dom';
import { MARKETPLACE_ROUTES } from '../config/routes';
import { BrowseRoute } from '../features/browse/routes/BrowseRoute';
import { useFeature } from 'lib/featureFlags/features';
import { useAzureFeatureFlags } from 'lib/featureFlags/azure-feature-flags';
import { LoadingScreen } from 'features/common/components/LoadingScreen';
import { AppDetails } from '../features/app-details';
import { InstallationSuccessModal } from '../features/app-details/components/InstallationSuccessModal';

export const MarketplaceRoutes: React.FC = () => {
  const { isLoading } = useAzureFeatureFlags({ enabled: true });
  const featureEnabled = useFeature('MARKETPLACE');

  if (isLoading) {
    return <LoadingScreen data-testid='loading-component' message='Loading…' />;
  }
  if (!featureEnabled && !isLoading) {
    return null;
  }

  return (
    <Route path={MARKETPLACE_ROUTES.ROOT}>
      <Switch>
        <Route
          exact
          path={`${MARKETPLACE_ROUTES.ROOT}`}
          component={BrowseRoute}
        />
        <MarketplaceAppDetailsRoute>
          {/* IF app details and installation are sharing same layout, please read https://gist.github.com/ryanflorence/f812198561c58aec1326ac800e6ea519#layout-based-routing */}
          <Route
            exact
            path={`${MARKETPLACE_ROUTES.ROOT}${MARKETPLACE_ROUTES.INSTALLATION}`}
            render={() => (
              <div data-testid='installation-page'>Installation Page</div>
            )}
          />
          <Route
            exact
            path={`${MARKETPLACE_ROUTES.ROOT}${MARKETPLACE_ROUTES.INSTALLATION_SUCCESS}`}
            component={InstallationSuccessModal}
          />
        </MarketplaceAppDetailsRoute>
      </Switch>
    </Route>
  );
};

const MarketplaceAppDetailsRoute = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Route path={`${MARKETPLACE_ROUTES.ROOT}${MARKETPLACE_ROUTES.APP_DETAILS}`}>
    <AppDetails />
    {children}
  </Route>
);
