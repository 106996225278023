import {
  Flex,
  Heading,
  IconButton,
  Link,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { mdiLinkVariant } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { Application } from 'features/marketplace/shared/types';
import { useEffect } from 'react';

type AppNameSectionProps = Pick<Application, 'name'>;

export const AppNameSection = ({ name }: AppNameSectionProps) => {
  const toast = useToast();
  const { onCopy, hasCopied } = useClipboard(window.location.href);

  useEffect(() => {
    if (hasCopied) {
      toast({
        status: 'success',
        description: 'Copied',
        duration: 2000,
      });
    }
  }, [hasCopied, toast]);
  return (
    <Flex
      flexDir='column'
      gap={1}
      data-testid='app-details-header-app-name-container'
      justifyContent='center'
    >
      <Flex gap={1} alignItems='center'>
        <Heading as='h1' data-testid='app-details-header-app-name'>
          {name}
        </Heading>
        <IconButton
          size='sm'
          variant='ghost'
          onClick={onCopy}
          icon={<PortalIcon path={mdiLinkVariant} />}
          aria-label='Copy url'
          data-testid='app-details-header-copy-url-icon'
        />
      </Flex>
      <Text
        fontSize='x-small'
        variant='subtle'
        data-testid='app-details-header-disclaimer'
      >
        by{' '}
        <Link
          isExternal
          href='https://doc.sitecore.com/'
          data-testid='app-details-header-disclaimer-link'
        >
          Sitecore
        </Link>
      </Text>
    </Flex>
  );
};
