import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  Skeleton,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router';
import { mdiCheckCircle, mdiOpenInNew } from '@mdi/js';
import { getAppPath } from '../../../../config/routes';
import { ImageWithSkeleton } from '../../../../shared/components';
import { useGetApplication } from '../../hooks';
import { defaultAppValues } from '../../../../shared/utils/constants';
import { xmCloud } from 'features/marketplace/shared/utils/icons';

export const InstallationSuccessModal = () => {
  const { appId } = useParams<{ appId: string }>();
  const history = useHistory();

  const { data, isLoading } = useGetApplication(appId);
  const { name, presentation } = data ?? defaultAppValues;

  const onClose = () =>
    history.replace({
      pathname: getAppPath(appId),
      search: history.location.search,
    });
  return (
    <Modal
      isOpen
      onClose={onClose}
      data-testid='installation-success-modal'
      size='xl'
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Skeleton isLoaded={!isLoading}>
            <Heading size='md' data-testid='installation-success-modal-heading'>
              Install {name}
            </Heading>
          </Skeleton>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection='column' alignItems='center' gap={6}>
            <Flex
              w='lg'
              h='32'
              p={10}
              borderRadius='md'
              gap={4}
              backgroundColor='chakra-subtle-bg'
              justifyContent='center'
              alignItems='center'
            >
              <ImageWithSkeleton
                w='12'
                h='12'
                src={presentation?.iconUrl ?? ''}
              />
              <Icon color='success'>
                <path d={mdiCheckCircle} />
              </Icon>
              <ImageWithSkeleton w='12' h='12' src={xmCloud} />
            </Flex>
            <Alert status='success'>
              <AlertIcon />
              <AlertDescription>
                The app was installed successfully
              </AlertDescription>
            </Alert>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant='outline'
              onClick={onClose}
              data-testid='installation-success-modal-dismiss'
              data-behavior-analytics-id='installation-success-modal-dismiss'
            >
              Close
            </Button>
            <Button
              variant='solid'
              //TODO add onClick functionality when url discussion is resolved
              //   onClick={() =>
              //     window.open(
              //       provision?.url ?? '',
              //       '_blank',
              //       'noopener,noreferrer',
              //     )
              //   }
              isLoading={isLoading}
              data-testid='installation-success-modal-open-app'
              data-behavior-analytics-id='installation-success-modal-open-app'
              leftIcon={
                <Icon>
                  <path d={mdiOpenInNew} />
                </Icon>
              }
            >
              Open app
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
