export const defaultAppValues = {
  name: '',
  presentation: {
    iconUrl: null,
    categories: null,
    description: null,
    screenshots: null,
    websiteUrl: null,
    supportUrl: null,
    privacyPolicyUrl: null,
    termsOfServiceUrl: null,
  },
  provision: { url: null },
};
