import { Flex } from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/marketplace/shared/components';
import { Presentation, Application } from 'features/marketplace/shared/types';
import { AppActionSection } from '../AppActionSection';
import { AppNameSection } from '../AppNameSection';

type HeaderProps = Pick<Presentation, 'iconUrl'> & Pick<Application, 'name'>;

export const Header = ({ name, iconUrl }: HeaderProps) => (
  <Flex data-testid='app-details-header' justifyContent='space-between'>
    <Flex gap={3} data-testid='app-details-header-icon-container'>
      <ImageWithSkeleton
        src={iconUrl ?? ''}
        w='20'
        minW='20'
        alt='data.appName-icon'
      />
      <AppNameSection name={name} />
    </Flex>
    <AppActionSection />
  </Flex>
);
