import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react';

export const SidebarSkeleton = () => (
  <Flex
    data-testid='app-details-body-sidebar-skeleton'
    flexDir='column'
    gap={7}
  >
    <Flex
      flexDir='column'
      gap={2}
      data-testid='app-details-body-sidebar-developer-container-skeleton'
    >
      <SkeletonText noOfLines={1} width='24' />
      <SkeletonText noOfLines={1} width='36' />
    </Flex>
    <Flex
      flexDir='column'
      gap={2}
      data-testid='app-details-body-sidebar-works-container-skeleton'
    >
      <SkeletonText noOfLines={1} width='24' />
      <SkeletonText noOfLines={1} width='36' />
    </Flex>
    <Flex
      flexDir='column'
      gap={2}
      data-testid='app-details-body-sidebar-resources-container-skeleton'
    >
      <SkeletonText noOfLines={1} width='24' />
      <Skeleton height='5' width='24' />
      <Skeleton height='5' width='24' />
      <Skeleton height='5' width='24' />
      <Skeleton height='5' width='24' />
    </Flex>
    <Flex
      flexDir='column'
      gap={2}
      data-testid='app-details-body-sidebar-categories-container-skeleton'
    >
      <SkeletonText noOfLines={1} width='24' />
      <Flex gap={2} wrap='wrap'>
        <Skeleton height='7' width='14' />
        <Skeleton height='7' width='14' />
        <Skeleton height='7' width='14' />
      </Flex>
    </Flex>
  </Flex>
);
