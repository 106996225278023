import { MARKETPLACE_KEYS } from '../../../config/query-keys';
import { browseEndpoints } from '../api/browse-api';
import { useAuthQuery } from '../../../core/auth/hooks/useAuthQuery';
import { ApplicationListResponse } from '../../../shared/types';

export const useTopPicks = (pageNumber: number = 1, pageSize: number = 10) => {
  const query = useAuthQuery<ApplicationListResponse>({
    queryKey: MARKETPLACE_KEYS.apps.list,
    url: browseEndpoints.getTopPicks().url,
    method: browseEndpoints.getTopPicks().method,
  });

  return {
    ...query,
    data: query.data?.data ?? null,
    pagination: {
      totalCount: query.data?.totalCount ?? 0,
      pageSize: query.data?.pageSize ?? pageSize,
      pageNumber: query.data?.pageNumber ?? pageNumber,
    },
  };
};
