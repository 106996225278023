import { Box, Image, ImageProps, Skeleton } from '@chakra-ui/react';
import { useState } from 'react';

export const ImageWithSkeleton = (props: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box position='relative' width={props.w} height={props.h}>
      <Skeleton
        isLoaded={isLoaded}
        width={props.w}
        height={props.h}
        borderRadius={props.borderRadius}
      >
        <Image
          fallbackSrc='https://via.placeholder.com/56'
          {...props}
          onLoad={() => setIsLoaded(true)}
        />
      </Skeleton>
    </Box>
  );
};
