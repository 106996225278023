import { MARKETPLACE_INSTALLED_LIST } from 'features/marketplace/config/query-keys';
import { useAuthMutation } from 'features/marketplace/core/auth/hooks/useAuthMutation';
import { useQueryClient } from 'react-query';
import { marketplaceApi } from '../api/endpoints';

export const useInstallApplication = (mutationOptions?: any) => {
  const queryClient = useQueryClient();
  const {
    install_application: { method, url },
  } = marketplaceApi;
  return useAuthMutation<{ appId: string }>({
    ...mutationOptions,
    onSuccess: (res) => {
      mutationOptions?.onSuccess && mutationOptions.onSuccess(res);
      queryClient.invalidateQueries(MARKETPLACE_INSTALLED_LIST);
    },
    url: url(),
    method,
  });
};
