import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Divider,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import { HeaderSkeleton, BodySkeleton, SidebarSkeleton } from './components';

export const AppDetailsSkeleton = () => (
  <Container maxW='container.2xl' px={{ base: '4', md: '8', lg: '16' }}>
    <Flex flexDir='column' gap={8} mt={6}>
      <Breadcrumb data-testid='app-details-breadcrumb'>
        <BreadcrumbItem>
          <Skeleton height='5' width='24' />
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <Skeleton height='5' width='24' />
        </BreadcrumbItem>
      </Breadcrumb>
      <HeaderSkeleton />
      <Divider px={4} />
      <Flex data-testid='app-details-body' gap={16}>
        <BodySkeleton />
        <SidebarSkeleton />
      </Flex>
    </Flex>
  </Container>
);
