import { Flex, Heading, Link, Text } from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/marketplace/shared/components';
import { Presentation } from 'features/marketplace/shared/types';

type BodyProps = Pick<Presentation, 'description' | 'screenshots'>;

export const Body = ({ description, screenshots }: BodyProps) => (
  <Flex data-testid='app-details-body-main' flexDir='column' gap={10}>
    <Flex data-testid='app-details-body-text'>
      <Text maxW='4xl'>{description}</Text>
    </Flex>
    {screenshots?.length ? (
      <Flex
        data-testid='app-details-body-screenshots-container'
        flexDir='column'
        gap={4}
      >
        <Heading as='h4' size='md'>
          Screenshots
        </Heading>
        <Flex
          data-testid='app-details-body-screenshots'
          gap={4}
          flexWrap='wrap'
          maxW='5xl'
        >
          {screenshots.map(({ url, caption }, i) => (
            <Link
              key={`${url}-${caption}-${i}`}
              isExternal
              href={url ?? ''}
              data-testid={`app-details-screenshot-disclaimer-link-${caption}`}
            >
              <ImageWithSkeleton
                src={url ?? ''}
                alt={caption ?? ''}
                width='80'
                minW='80'
                height='48'
                borderRadius='lg'
              />
            </Link>
          ))}
        </Flex>
      </Flex>
    ) : null}
  </Flex>
);
