import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Wrap,
  WrapItem,
  VStack,
  Link,
  Tag,
  Badge,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import { Application, Presentation } from '../../shared/types';
import { getAppPath } from 'features/marketplace/config/routes';
import { ImageWithSkeleton } from './ImageWithSkeleton';

interface AppCardProps {
  app: Application;
  isInstalled: boolean;
  isFetching: boolean;
}

export const AppCard: React.FC<AppCardProps> = ({
  app,
  isInstalled,
  isFetching,
}) => {
  const presentation = app.presentation as Presentation;

  return (
    <Box
      data-testid='app-card'
      borderWidth='1px'
      borderRadius='lg'
      p='5'
      display='flex'
      flexDirection='column'
      gap='2'
      backgroundColor='chakra-body-bg'
      width='100%'
    >
      <VStack
        data-testid='app-card-content'
        align='start'
        spacing='2'
        width='100%'
      >
        <Flex justifyContent='space-between' w='full'>
          <ImageWithSkeleton
            data-testid='app-icon'
            src={presentation.iconUrl!}
            alt={`${app.name} icon`}
            width='14'
            height='14'
            objectFit='cover'
          />
          <Skeleton isLoaded={!isFetching} width='20' height='5'>
            {isInstalled && (
              <Badge colorScheme='success' height='fit-content'>
                installed
              </Badge>
            )}
          </Skeleton>
        </Flex>

        <Heading as='h3' size='md' fontWeight='semibold'>
          <RouterLink
            to={getAppPath(app.id!)}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {app.name}
          </RouterLink>
        </Heading>
        <VStack align='start' gap='0.5rem'>
          <Text fontSize='sm' color='gray.600' noOfLines={2} lineHeight='1.5'>
            {presentation.description}
          </Text>

          <Wrap spacing='2'>
            {presentation?.categories?.map((category) => (
              <WrapItem key={category}>
                <Tag>{category}</Tag>
              </WrapItem>
            ))}
          </Wrap>

          <Text fontSize='md' color='gray.600'>
            <Wrap alignItems='center' gap='0.5rem'>
              <Text fontSize='sm'>by</Text>
              <Link color='purple.600' _hover={{ textDecoration: 'underline' }}>
                {/* NOTE: not defined in schema yet */}
                {presentation?.organizationName ?? 'Sitecore'}
              </Link>
            </Wrap>
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
