import { MARKETPLACE_KEYS } from 'features/marketplace/config/query-keys';
import { InstalledApplicationListResponse } from 'features/marketplace/shared/types';
import { useAuthQuery } from '../../../core/auth/hooks/useAuthQuery';
import { marketplaceApi } from '../api/endpoints';

export const useGetInstalledApplications = ({
  appId = '',
  page = '1',
  pageSize = '100',
  queryOptions,
}: {
  appId?: string;
  page?: string;
  pageSize?: string;
  queryOptions?: any;
}) => {
  const {
    get_installed_applications: { method, url },
  } = marketplaceApi;
  return useAuthQuery<InstalledApplicationListResponse>({
    ...queryOptions,
    queryKey: MARKETPLACE_KEYS.apps.installed_applications({ page, pageSize }),
    url: url({ page, pageSize, appId }),
    method,
  });
};
